#settings {
  opacity: 0;
  z-index: 201;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-family: Ubuntu;
  animation: .2s linear forwards fadeIn;
  display: flex;
  position: absolute;
}

#settings-content {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 308.4px;
  animation: .6s linear scaleUp;
  display: flex;
}

@media (width <= 1366px) {
  #settings-content {
    text-align: center;
    background: #fff;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    width: 320px;
    animation: .6s linear scaleUp;
    display: flex;
    transform: scale(.7);
  }
}

#settings-title {
  color: #fff;
  text-align: center;
  background: #00b3ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 26px;
}

#settings-btns {
  color: #fff;
  background-color: #00b3ff;
  height: 55px;
  margin-top: -7px;
  position: relative;
}

#settings-body {
  z-index: 1;
  flex-flow: column wrap;
  width: 330px;
  height: 190px;
  min-height: 0;
  margin-top: -22px;
  display: flex;
}

#controls {
  opacity: 0;
  z-index: 0;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  margin-top: 70px;
  margin-left: 10px;
  display: flex;
  position: absolute;
}

.keybind-row {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 10px;
  margin-left: 50px;
  display: flex;
}

.keybind-row p {
  flex: 1;
  margin: 0;
}

.keybindButton {
  flex: 0 auto;
  width: 76px;
}

#button1 {
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  color: #fff;
  letter-spacing: -1px;
  float: left;
  z-index: 1;
  background-image: linear-gradient(#ffa445 70%, #e1903b 50%);
  border: 3px solid #000;
  border-radius: 14px;
  width: 142px;
  height: 38px;
  font-family: Ubuntu;
  font-size: 25px;
  position: relative;
  top: 18px;
  left: 1px;
  transform: scale(.8);
}

#button1:hover {
  -webkit-text-stroke: black;
  color: #fff;
  -webkit-text-stroke-width: 2px;
  cursor: pointer;
  background-image: linear-gradient(#fdbd7a 70%, #ebb072 50%);
  border-color: #000;
}

#button2 {
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 2px;
  color: #fff;
  letter-spacing: -1px;
  float: right;
  z-index: 1;
  background-image: linear-gradient(#ffa445 70%, #e1903b 50%);
  border: 3px solid #000;
  border-radius: 14px;
  width: 142px;
  height: 38px;
  font-family: Ubuntu;
  font-size: 25px;
  position: relative;
  top: 18px;
  right: 1px;
  transform: scale(.8);
}

#button2:hover {
  -webkit-text-stroke: black;
  color: #fff;
  -webkit-text-stroke-width: 2px;
  cursor: pointer;
  background-image: linear-gradient(#fdbd7a 70%, #ebb072 50%);
  border-color: #000;
}

#settings-selector {
  background-color: #fff;
  border-top-right-radius: 29px;
  width: 143px;
  height: 45px;
  position: relative;
  top: -44px;
  left: 0;
}

.slideRight {
  animation: .5s linear forwards slideRight;
}

.slideLeft {
  animation: .5s linear forwards slideLeft;
}

#settings-body label {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-left: 36px;
  font-weight: 700;
  display: flex;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideLeft {
  0% {
    left: 0;
  }

  50% {
    border-top-right-radius: 29px;
    left: 100px;
  }

  100% {
    border-top-left-radius: 29px;
    border-top-right-radius: 0;
    left: 177px;
  }
}

@keyframes slideRight {
  0% {
    border-top-left-radius: 29px;
    border-top-right-radius: 0;
    left: 177px;
  }

  50% {
    border-top-left-radius: 29px;
    left: 100px;
  }

  100% {
    border-top-left-radius: 0;
    border-top-right-radius: 29px;
    left: 0;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@media (width <= 1366px) {
  @keyframes scaleUp {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(.7);
    }
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

.selected-skin-animation {
  animation: 2s infinite pulse;
}
/*# sourceMappingURL=index.50736092.css.map */
