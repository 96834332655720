#settings {
	height: 100vh;
	width: 100vw;
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 201;
    display: flex;
    justify-content: center;
    align-items: center;
	font-family: 'Ubuntu';
	animation: fadeIn 0.2s linear forwards;
}
#settings-content {
    background: #fff;
    border-radius: 15px;
    display: flex;
    animation: scaleUp 0.6s linear;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 320px;
    height: 308.4px;
    user-select: none;
}
@media (max-width: 1366px) {
    #settings-content {
        background: #fff;
        border-radius: 15px;
        display: flex;
        animation: scaleUp 0.6s linear;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 320px;
        transform: scale(0.7);
    }
  }


#settings-title {
    font-size: 26px;
    background: rgb(0, 179, 255);
    color: #fff;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
#settings-btns {
    color: white;
    height: 55px;
    background-color: rgb(0, 179, 255);
    margin-top: -7px;
    position: relative;
}
#settings-body {
    display: flex;
    min-height: 0;
    flex-direction: column;
    width: 330px;
    height: 190px;
    flex-wrap: wrap;
    margin-top: -22px;
    z-index: 1;
}
#controls {
    position: absolute;
    width: 300px;
    margin-left: 10px;
    opacity: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 70px;
}
.keybind-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 50px;
}

.keybind-row p {
    flex: 1; /* Allows the title to take up equal space */
    margin: 0; /* Remove default paragraph margins */
}

.keybindButton {
    flex: 0 1 auto;
    width: 76px;
}
#button1 {
    height: 38px;
    width: 142px;
    background-image: linear-gradient(to bottom, #ffa445 70%, #e1903b 50%);
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    color: #ffffff;
    font-family: 'Ubuntu';
    font-size: 25px;
    border-radius: 14px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
    letter-spacing: -1px;
    transform: scale(0.8);
    float: left;
    position: relative;
    top: 18px;
    left: 1px;
    z-index: 1;
}
#button1:hover {
    background-image: linear-gradient(to bottom, #fdbd7a 70%, #ebb072 50%);
    -webkit-text-stroke: black;
    color: #ffffff;
    border-color: #000000;
    -webkit-text-stroke-width: 2px;
    cursor: pointer;
}
#button2 {
    height: 38px;
    width: 142px;
    background-image: linear-gradient(to bottom, #ffa445 70%, #e1903b 50%);
    -webkit-text-stroke: black;
    -webkit-text-stroke-width: 2px;
    color: #ffffff;
    font-family: 'Ubuntu';
    font-size: 25px;
    border-radius: 14px;
    border-width: 3px;
    border-style: solid;
    border-color: #000000;
    letter-spacing: -1px;
    transform: scale(0.8);
    float: right;
    position: relative;
    top: 18px;
    right: 1px;
    z-index: 1;
}
#button2:hover {
    background-image: linear-gradient(to bottom, #fdbd7a 70%, #ebb072 50%);
    -webkit-text-stroke: black;
    color: #ffffff;
    border-color: #000000;
    -webkit-text-stroke-width: 2px;
    cursor: pointer;
}
#settings-selector{
    width: 143px;
    height: 45px;
    background-color: rgb(255 255 255);
    position: relative;
    top: -44px;
    left: 0px;
    border-top-right-radius: 29px;
}
.slideRight{
    animation: slideRight 0.5s linear forwards;
}
.slideLeft{
    animation: slideLeft 0.5s linear forwards;
}
#settings-body label {
	font-weight: 700;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 36px;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slideLeft {
    0% {
        left: 0;
    }
    50% {
        left: 100px;
        border-top-right-radius: 29px; 
    }
    100% {
        border-top-left-radius: 29px; 
        border-top-right-radius: 0px;
        left: 177px;
    }
}
@keyframes slideRight {
    0% {
        left: 177px;
        border-top-left-radius: 29px; 
        border-top-right-radius: 0px;
    }
    50% {
        left: 100px;
        border-top-left-radius: 29px; 
    }
    100% {
        border-top-left-radius: 0px; 
        border-top-right-radius: 29px;
        left: 0px;
    }
}

@keyframes scaleUp {
  from {transform: scale(0);} 
  to {transform: scale(1);}
}

@media (max-width: 1366px) {
    @keyframes scaleUp {
        from {transform: scale(0);} 
        to {transform: scale(0.7);}
      }
  }


@keyframes scaleDown {
  from {transform: scale(1);} 
  to {transform: scale(0);}
}

.selected-skin-animation {
    animation: pulse 2s infinite;
}